import Messages from '../messages'

export const request = async (url, method, body, auth, formData) => {
  let headers = {}
  if (!formData) {
    headers = {
      'Content-Type': 'application/json',
    }
  }
  if (auth) {
    const jwtToken = localStorage.getItem('token')
    headers.Authorization = 'Bearer ' + jwtToken
  }

  let data = { method, headers }
  if (method === 'POST' || method === 'PUT') {
    data = { method, body, headers }
  }

  return fetch(url, data)
    .then((response) => {
      if (response.status === 502) {
        throw new Error(Messages.NetWorkError)
      }
      return response.json()
    })
    .catch((error) => {
      console.log('api error: ', error)
      return { error: error.message }
    })
}
