import React, { useState, useEffect, Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import { connect } from 'react-redux'
import actionTypes from './actions/actionTypes'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './scss/style.scss'

import { getMe } from './api/users'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))

const UnauthenticatedRoute = () => (
  <Routes>
    <Route exact path="/" name="Login Page" element={<Login />} />
  </Routes>
)

const AuthenticatedRoute = () => (
  <Routes>
    <Route path="*" name="Home" element={<DefaultLayout />} />
    <Route exact path="/login" name="Login Page" element={<Login />} />
  </Routes>
)

const App = (props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  useEffect(() => {
    async function fetchMe() {
      const token = localStorage.getItem('token')
      if (token && token.length > 0) {
        const response = await getMe()
        if (response && response.result) {
          props.dispatch({
            type: actionTypes.SET_CURRENT_USER,
            payload: response.user,
          })
        } else {
          console.log('error')
          localStorage.removeItem('token')
          document.location.href = '/'
        }
      }
    }
    fetchMe()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (props.currentUser && props.currentUser._id) {
      setIsAuthenticated(true)
    }
  }, [props.currentUser])

  return (
    <HashRouter>
      <Suspense fallback={loading}>
        {isAuthenticated ? <AuthenticatedRoute /> : <UnauthenticatedRoute />}
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </Suspense>
    </HashRouter>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.users.currentUser,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
