import { createReducer } from 'reduxsauce'
import Types from '../actions/actionTypes'

export const initialState = {
  currentUser: null,
  sidebarShow: true,
}

////////////////////////////////////////////////////////////////////
////////////////////// Set Current User ////////////////////////////
////////////////////////////////////////////////////////////////////
const setCurrentUser = (state, action) => ({
  ...state,
  currentUser: action.payload,
})

////////////////////////////////////////////////////////////////////
///////////////////// Change Sidebar Show //////////////////////////
////////////////////////////////////////////////////////////////////
const changeSideBarShow = (state, action) => ({
  ...state,
  sidebarShow: action.payload,
})

////////////////////////////////////////////////////////////////////
/////////////////////////// Reset //////////////////////////////////
////////////////////////////////////////////////////////////////////
const resetUser = (state) => ({
  ...state,

  currentUser: null,
  sidebarShow: true,
})

const actionHandlers = {
  [Types.SET_CURRENT_USER]: setCurrentUser,
  [Types.CHANGE_SIDEBAR_SHOW]: changeSideBarShow,
  [Types.RESET_USER]: resetUser,
}

export default createReducer(initialState, actionHandlers)
