import { combineReducers } from 'redux'
import users from './users'

const applicationReducers = {
  users,
}

export default function createReducer() {
  return combineReducers(applicationReducers)
}
