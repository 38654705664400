// export const API_URL = 'http://localhost:3000'
// export const API_URL = 'https://dev.tgsisthegoodsteward.com'
export const API_URL = 'https://live.tgsisthegoodsteward.com'
export const SUBSCRIPTION_LEVEL = {
  FREE: 'free',
  STARTER: 'starter',
  HOME_BUYER: 'home_buyer',
  DETERMINED: 'determined',
}

export const ARTICLE_TYPE = {
  LINK: 'link',
  CONTENT: 'content',
}
