import { API_URL } from '../constants'
import { request } from './index'

////////////////////////////////////////////////////////////////////
////////////////////////// Login User //////////////////////////////
////////////////////////////////////////////////////////////////////
export const login = async (data) => {
  const method = 'POST'
  const url = `${API_URL}/dashboard/login`
  const body = JSON.stringify(data)
  return request(url, method, body)
}

////////////////////////////////////////////////////////////////////
/////////////////////////// Get Me /////////////////////////////////
////////////////////////////////////////////////////////////////////
export const getMe = async () => {
  const method = 'POST'
  const url = `${API_URL}/user/get_me`
  const body = JSON.stringify({})
  return request(url, method, body, true)
}

////////////////////////////////////////////////////////////////////
//////////////////////// Get Main Info /////////////////////////////
////////////////////////////////////////////////////////////////////
export const getMainInfo = async () => {
  const method = 'POST'
  const url = `${API_URL}/dashboard/get_main_info`
  const body = JSON.stringify({})
  return request(url, method, body, true)
}

////////////////////////////////////////////////////////////////////
//////////////////////// Get All Users /////////////////////////////
////////////////////////////////////////////////////////////////////
export const getAllUsers = async (data) => {
  const method = 'POST'
  const url = `${API_URL}/dashboard/get_all_users`
  const body = JSON.stringify(data)
  return request(url, method, body, true)
}
